//import React from 'react';
//import logo from './logo.svg';
//import './App.css';
//
//function App() {
//  return (
//    <div className="App">
//      <header className="App-header">
//        <img src={logo} className="App-logo" alt="logo" />
//        <p>
//          Edit <code>src/App.js</code> and save to reload.
//        </p>
//        <a
//          className="App-link"
//          href="https://reactjs.org"
//          target="_blank"
//          rel="noopener noreferrer"
//        >
//          Learn React
//        </a>
//      </header>
//    </div>
//  );
//}
//
//export default App;
//
//
import React, {
  Component
}
from 'react';
//import logo from './logo.svg';
import bg from './white-house.jpg';
import './App.css';
import $ from 'jquery';



class App extends Component {

  constructor(props) {
      super(props);


      this.state = {
          go: false,
          month: "",
          day: "",
          year: "",
          date_ms: null
      }

      this.handle_input_change = this.handle_input_change.bind(this)
      this.handle_submit = this.handle_submit.bind(this)


  }


  componentWillMount() {

      /*IE check so we can add a bit of extra CSS*/
      var ie = 0;
      try { ie = navigator.userAgent.match( /(MSIE |Trident.*rv[ :])([0-9]+)/ )[ 2 ]; }
      catch(e){}


      if (ie < 12 && ie !== 0) {
          $('html').addClass('ie');
      }

  }


  componentDidMount() {

      var bg_string = "url(" + bg + ")";


      $(window).on("load", function() {
          $('html').css('background-image', bg_string);
          $('.overlay').fadeOut();

          /*Do the same for footer for now (needs to go in component eventually)*/
          $('p.text').show();
      });

  }


  render() {
      return (

          <div>
              <div className="container">
                  <div className="jumbotron">
                      <h1>Who was President of the <span>United States of America</span> on...</h1>

                      <form onSubmit={this.handle_submit}>
                          <div className="row">

                              <div className="col-xs-6 col-sm-3 col-md-3 col-lg-2">
                                  <div className="form-group" id="month">
                                      <label htmlFor="month">Month</label>
                                      <select name="month" type="text" onChange={this.handle_input_change}  className="form-control input-lg" value={this.state.month} id="month" >
                                          <option value="0">Month</option>
                                          <option value="1">January</option>
                                          <option value="2">February</option>
                                          <option value="3">March</option>
                                          <option value="4">April</option>
                                          <option value="5">May</option>
                                          <option value="6">June</option>
                                          <option value="7">July</option>
                                          <option value="8">August</option>
                                          <option value="9">September</option>
                                          <option value="10">October</option>
                                          <option value="11">November</option>
                                          <option value="12">December</option>
                                      </select>
                                      <span className="help-block">Please enter a valid month</span>
                                  </div>
                              </div>
                              <div className="col-xs-6 col-sm-3 col-md-3 col-lg-2">
                                  <div className="form-group" id="day">
                                      <label htmlFor="day">Day</label>
                                      <select name="day" type="text" onChange={this.handle_input_change} className="form-control input-lg" value={this.state.day} id="day" >
                                          <option value="0">Day</option>
                                          <option value="1">1</option>
                                          <option value="2">2</option>
                                          <option value="3">3</option>
                                          <option value="4">4</option>
                                          <option value="5">5</option>
                                          <option value="6">6</option>
                                          <option value="7">7</option>
                                          <option value="8">8</option>
                                          <option value="9">9</option>
                                          <option value="10">10</option>
                                          <option value="11">11</option>
                                          <option value="12">12</option>
                                          <option value="13">13</option>
                                          <option value="14">14</option>
                                          <option value="15">15</option>
                                          <option value="16">16</option>
                                          <option value="17">17</option>
                                          <option value="18">18</option>
                                          <option value="19">19</option>
                                          <option value="20">20</option>
                                          <option value="21">21</option>
                                          <option value="22">22</option>
                                          <option value="23">23</option>
                                          <option value="24">24</option>
                                          <option value="25">25</option>
                                          <option value="26">26</option>
                                          <option value="27">27</option>
                                          <option value="28">28</option>
                                          <option value="29">29</option>
                                          <option value="30">30</option>
                                          <option value="31">31</option>
                                      </select>
                                      <span className="help-block">Please enter a valid day</span>

                                  </div>
                              </div>
                              <div className="col-xs-6 col-sm-3 col-md-3 col-lg-2">
                                  <div className="form-group" id="year">
                                      <label htmlFor="year">Year</label>
                                      <input max="9999" name="year" type="number" pattern="[0-9]*" onInput={this.handle_input_change} className="form-control input-lg" value={this.state.year}  id="datetime" placeholder="e.g. 1802" />
                                      <span className="help-block">Please enter a valid year</span>
                                  </div>
                              </div>
                              <div className="col-xs-6 col-sm-3 col-md-3 col-lg-2">
                                  <div className="form-group">

                                    <button className="btn btn-primary btn-lg" href="#" type="submit">Go!</button>



                                      <input type="hidden" id="hidden-button" data-toggle="modal" data-target="#modal" />



                                  </div>
                              </div>
   
                          </div>



                          <div className="row" id="before-washington">
                              <div className="col-sm-12">
                                  <div className="alert alert-danger" role="alert">Try a date on or after April 30, 1789 (the inauguration of George Washington).</div>
                              </div>
                          </div>

                          <div className="row" id="in-the-future">
                              <div className="col-sm-12">
                                  <div className="alert alert-danger" role="alert">This date is in the future, try a date between April 30, 1789 and Now!</div>
                              </div>
                          </div>



                                          <div className="info"><p>Use this tool to find out information about the US presidency on a certain date in history. It features information regarding term dates, the president's predecessor and successor, days from the beginning and to the end of the presidency, a visualization of the president's terms in relation to the chosen date, and also a set of Wikipedia links for more information. </p></div>

                      </form>


                  </div>
              </div>



              <Modal go={this.state.go} day={this.state.day} month={this.state.month} year={this.state.year} pres={this.props.pres} date_ms={this.state.date_ms}></Modal>

          </div>

      )
  }



  handle_input_change(event) {
      const target = event.target;
      const value = target.value;
      const name = target.name;

      this.setState({
          go: false,
          [name]: value
      })



      /*
      Error handling
      */
      var month_form_group_classes = document.getElementById("month").classList;
      var day_form_group_classes = document.getElementById("day").classList;
      var year_form_group_classes = document.getElementById("year").classList;

      var before_washington = document.getElementById("before-washington");
      var in_the_future = document.getElementById("in-the-future");

      before_washington.style.display = "none";
      in_the_future.style.display = "none";

      /*Init error classes*/
      if (month_form_group_classes.contains("has-error")) {
          month_form_group_classes.remove("has-error");
      }

      if (day_form_group_classes.contains("has-error")) {
          day_form_group_classes.remove("has-error");
      }

      if (year_form_group_classes.contains("has-error")) {
          year_form_group_classes.remove("has-error");
      }




  }


  handle_submit(event) {
      event.preventDefault();

      /*Remember, if month is a part of date-as-number we need to subtract 1 because it is zero based, however when date format is a string we do not need to do this*/


      /*Remove zeros: 1-Calculations are slightly off otherwise, 2-Looks better for pretty print*/
      var day = this.state.day;
      var month = this.state.month;

      day = parseInt(day, 10);
      month = parseInt(month, 10) - 1;

      var the_date_full = new Date(this.state.year, month, day);
      var date_ms = the_date_full.getTime();


      /*
      Error handling
      */

      /* Get year, month, and day of today */
      var today_full = new Date();
      var today_m = today_full.getMonth();
      var today_d = today_full.getDate();
      var today_y = today_full.getFullYear();
      var today = new Date(today_y, today_m, today_d);
      today = today.getTime();
      /**/
      var washington = new Date('April 30, 1789');
      var washington_ms = washington.getTime();
      var errors = false;
      var month_form_group_classes = document.getElementById("month").classList;
      var day_form_group_classes = document.getElementById("day").classList;
      var year_form_group_classes = document.getElementById("year").classList;

      var before_washington = document.getElementById("before-washington");
      var in_the_future = document.getElementById("in-the-future");

      before_washington.style.display = "none";
      in_the_future.style.display = "none";

      /*Init error classes*/
      if (month_form_group_classes.contains("has-error")) {
          month_form_group_classes.remove("has-error");
      }

      if (day_form_group_classes.contains("has-error")) {
          day_form_group_classes.remove("has-error");
      }

      if (year_form_group_classes.contains("has-error")) {
          year_form_group_classes.remove("has-error");
      }



      /*Months*/
      if (this.state.month === "0"  || this.state.month === "") {errors=true;month_form_group_classes.add("has-error")};

      /*Days - If day is greater than (dependent on month)*/
      function leapYear(year)
      {
          return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
      }

      if (this.state.day === "0" || this.state.day === "") {errors=true;day_form_group_classes.add("has-error")};
      if (parseInt(this.state.day, 10) === 0) {errors=true;day_form_group_classes.add("has-error")};
      if (parseInt(this.state.month, 10) === 1 && parseInt(this.state.day, 10) > 31) {errors=true;day_form_group_classes.add("has-error")};
      if (parseInt(this.state.month, 10) === 2 && parseInt(this.state.day, 10) > 28) {
          if (parseInt(this.state.month, 10) === 2 && parseInt(this.state.day, 10) === 29 && leapYear(parseInt(this.state.year, 10)) === false) {
              errors=true;day_form_group_classes.add("has-error")
          } else if (parseInt(this.state.month, 10) === 2 && parseInt(this.state.day, 10) === 29 && leapYear(parseInt(this.state.year, 10)) === true) {
              /*Fine*/
          } else {
              errors=true;day_form_group_classes.add("has-error")
          }
      };
      if (parseInt(this.state.month, 10) === 3 && parseInt(this.state.day, 10) > 31) {errors=true;day_form_group_classes.add("has-error")};
      if (parseInt(this.state.month, 10) === 4 && parseInt(this.state.day, 10) > 30) {errors=true;day_form_group_classes.add("has-error")};
      if (parseInt(this.state.month, 10) === 5 && parseInt(this.state.day, 10) > 31) {errors=true;day_form_group_classes.add("has-error")};
      if (parseInt(this.state.month, 10) === 6 && parseInt(this.state.day, 10) > 30) {errors=true;day_form_group_classes.add("has-error")};
      if (parseInt(this.state.month, 10) === 7 && parseInt(this.state.day, 10) > 31) {errors=true;day_form_group_classes.add("has-error")};
      if (parseInt(this.state.month, 10) === 8 && parseInt(this.state.day, 10) > 31) {errors=true;day_form_group_classes.add("has-error")};
      if (parseInt(this.state.month, 10) === 9 && parseInt(this.state.day, 10) > 30) {errors=true;day_form_group_classes.add("has-error")};
      if (parseInt(this.state.month, 10) === 10 && parseInt(this.state.day, 10) > 31) {errors=true;day_form_group_classes.add("has-error")};
      if (parseInt(this.state.month, 10) === 11 && parseInt(this.state.day, 10) > 30) {errors=true;day_form_group_classes.add("has-error")};
      if (parseInt(this.state.month, 10) === 12 && parseInt(this.state.day, 10) > 31) {errors=true;day_form_group_classes.add("has-error")};


      /*Years
      if (parseInt(this.state.year, 10) > 9999) {errors=true;year_form_group_classes.add("has-error")};*/
      if (this.state.year === "") {errors=true;year_form_group_classes.add("has-error")};

      if (isNaN(this.state.year)) {errors=true;year_form_group_classes.add("has-error")};

      /*Need below, because the following date before check doesn't work with years less than 100. UPDATE before 1000 for FF and IE*/
      if (parseInt(this.state.year, 10) <= 999) {errors=true;year_form_group_classes.add("has-error")};


      /*If date is before 30 April 1789*/
      if (date_ms < washington_ms) {errors=true;before_washington.style.display = "block";};


      /*If after today*/
      if (date_ms > today) {errors=true;in_the_future.style.display = "block";};


      /*If there are errors, stop*/

      if (errors === true) return;

      /*
      /Error handling
      */



      this.setState (
          {
              go: true,
              date_ms: date_ms
          }
      )






      /*Show modal - find a less hacky way to do this sometime...*/
      document.getElementById('hidden-button').click();

  }

}
/*/App*/






class Modal extends Component {

  constructor(props) {
      super(props);

      this.state = {
          quote: "",
          president_info: "",
          terms_header: "",
          term_texts_html: [],
          term_divs_html: [],
          date_perc_css: {},
          pointer_class: "",
          wikipedia: "",
          incumbent_flag: ""
      }


      this.show_incumbent = this.show_incumbent.bind(this)

  }

  componentWillReceiveProps(nextProps) {

      var go = false;
      var date_ms = null;
      var day = "";
      var month = "";
      var year = "";


      if (nextProps.go !== this.props.go) {
          go = nextProps.go;
      } else {
          go = this.props.go;
      }



      if (go === false) return;



      if (nextProps.date_ms !== this.props.date_ms) {
          date_ms = nextProps.date_ms;
      } else {
          date_ms = this.props.date_ms;
      }

      if (nextProps.day !== this.props.day) {
          day = nextProps.day;
      } else {
          day = this.props.day;
      }

      if (nextProps.month !== this.props.month) {
          month = nextProps.month;
      } else {
         month = this.props.month;
      }

      if (nextProps.year !== this.props.year) {
          year = nextProps.year;
      } else {
          year = this.props.year;
      }

      var pres = this.props.pres;
      var index_pres = Object.keys(pres);
      var incumbent_flag = "";
      var date_pretty = convert_dates(parseInt(month, 10)) + " " + day + ", " + year;

      /* Get year, month, and day of today */
      var today_full = new Date();
      var today_m = today_full.getMonth();
      var today_d = today_full.getDate();
      var today_y = today_full.getFullYear();
      var today = new Date(today_y, today_m, today_d);
      today = today.getTime();
      /**/

      var term_divs = [];
      var term_texts = [];
      var terms_header = "";
      var date_perc_styles;
      var pointer_class = "";


      function createTerms(modifier) {
          /*Need newNum as the info sometimes needs to show the last President's details*/
          var newNum = num - modifier;
          var index_terms = Object.keys(pres[newNum]["terms"]);
          var start_of_pres = new Date(pres[newNum]["terms"]["1"]["term-start-date"]);
          var ms_array = [];
          var total_pres_length = null;
          var term_div = "";
          var term_text = "";
          var date_ms_of_pres = null;
          var date_perc_of_pres = null;
          var date_perc_of_pres_css_string = null;


          /*Go through each term, getting length in ms and adding to ms_array*/
          for (var x = 1; index_terms.length >= x; x++) {

              var x_string = x.toString();

              var start = new Date(pres[newNum]["terms"][x_string]["term-start-date"]);
              var end = new Date(pres[newNum]["terms"][x_string]["term-end-date"]);

              start = start.getTime();
              end = end.getTime();

              var term_ms = (end - start);

              ms_array.push(term_ms);

              total_pres_length = total_pres_length + term_ms;

              term_text = <li key={x} ><span>{x_string}.</span> {pres[newNum]["terms"][x_string]["term-start-date"]} - {pres[newNum]["terms"][x_string]["term-end-date"]}</li>
                  term_texts.push(term_text);
          }

          function nth(d) {
              if(d>3 && d<21) return 'th'; // thanks kennebec
              switch (d % 10) {
                  case 1:  return "st";
                  case 2:  return "nd";
                  case 3:  return "rd";
                  default: return "th";
              }
          }


          terms_header = <h2>{pres[newNum]["president"]["president_first_name"]} {pres[newNum]["president"]["president_last_name"]} ({pres[newNum]["birth_date"]["birth_date_year"]}-{pres[newNum]["death_date"]["death_date_year"]}),<br />{pres[newNum]["number"]}{nth(pres[newNum]["number"])} President of the USA</h2>

              /*Get percentages and create term html elements*/
              for (var y = 0; ms_array.length > y; y++) {

                  /*Term percentage boxes*/
                  var perc = (ms_array[y] / total_pres_length) * 100;
                  var div_style = {width: perc + "%"}
                  term_div = <div className="term-div" key={y} style={div_style}><span>{y+1}</span></div>
                      term_divs.push(term_div);
              }


          /*Get chosen date as percentage of pres*/
          date_ms_of_pres = date_ms - start_of_pres;

          date_perc_of_pres = Math.round((date_ms_of_pres / total_pres_length) * 100)



          date_perc_of_pres_css_string = "calc("+ date_perc_of_pres + "% - 8px)";

          date_perc_styles = {
              left: date_perc_of_pres_css_string
          }

          if (date_perc_of_pres < 50) {
              pointer_class = "right"
          }

      }

      function convert_dates(the_month) {
          if (the_month === 1) {return "January"};
          if (the_month === 2) {return "February"};
          if (the_month === 3) {return "March"};
          if (the_month === 4) {return "April"};
          if (the_month === 5) {return "May"};
          if (the_month === 6) {return "June"};
          if (the_month === 7) {return "July"};
          if (the_month === 8) {return "August"};
          if (the_month === 9) {return "September"};
          if (the_month === 10) {return "October"};
          if (the_month === 11) {return "November"};
          if (the_month === 12) {return "December"};
      }



      /*Create info*/
      for (var num = 0; index_pres.length > num; num++) {

          console.log(pres[num]["president"]["president_last_name"]);


          var days_since = Math.round((date_ms - pres[num]["pres_start_date_processed"])  / 86400000);
          var days_to_go = Math.round((pres[num]["pres_end_date_processed"] -date_ms)  / 86400000);
          var days_since_text = 'days';
          var days_to_go_text = 'days';
          var president_info_string = "";
          var date_string = "";
          var wikipedia_string = "";
          var quote_string = "";

          if (days_since === 1) {days_since_text = 'day'};
          if (days_to_go === 1) {days_to_go_text = 'day'};

          var pfn = pres[num]["president"]["president_first_name"];
          var pln = pres[num]["president"]["president_last_name"];
          var wiki = pres[num]["wikipedia_link"];
          var sdd = pres[num]["pres_start_date"]["pres_start_date_day"];
          var sdm = pres[num]["pres_start_date"]["pres_start_date_month"];
          var sdy = pres[num]["pres_start_date"]["pres_start_date_year"];

          var pfnPrev = null;
          var plnPrev = null;
          var wikiPrev = null;

          var pfnNext = null;
          var plnNext = null;
          var wikiNext = null;

          var edd = null;
          var edm = null;
          var edy = null;


          if (pres[num-1]) {
              pfnPrev = pres[num-1]["president"]["president_first_name"];
              plnPrev = pres[num-1]["president"]["president_last_name"];
              wikiPrev = pres[num-1]["wikipedia_link"];
          }

          if (pres[num+1]) {
              pfnNext = pres[num+1]["president"]["president_first_name"];
              plnNext = pres[num+1]["president"]["president_last_name"];
              wikiNext = pres[num+1]["wikipedia_link"];

              edd = pres[num]["pres_end_date"]["pres_end_date_day"];
              edm = pres[num]["pres_end_date"]["pres_end_date_month"];
              edy = pres[num]["pres_end_date"]["pres_end_date_year"];
          }



          /*The logic*/

          /*Not Washington or Incumbent*/
          if (pres[num-1] != null && pres[num]["number"] < index_pres.length) {

              if (date_ms > pres[num]["pres_start_date_processed"] &&date_ms < pres[num]["pres_end_date_processed"]) {
                  date_string = date_pretty;
                  quote_string = <div className="quote">&ldquo;{pres[num]["quote"]}&rdquo;</div>;
                  president_info_string = <div><h1>{pfn} {pln}</h1><p>was President; he had been in office since {convert_dates(sdm)} {sdd} {sdy} ({days_since} {days_since_text} before), taking over from <strong>{pfnPrev} {plnPrev}</strong>. President {pln} would be succeeded by <strong>{pfnNext} {plnNext}</strong> on {convert_dates(edm)} {edd} {edy} ({days_to_go} {days_to_go_text} later).</p></div>;
                  /*Need to have this so Benjamin Harrison doesn't have two Grove Cleveland Wikipedia links*/
                  if (pres[num]["number"] === 23) {
                      wikipedia_string =
                          <div>
                              <h3>Wikipedia Links:</h3>
                              <p><a target="_blank" href={wikiPrev}>{pfnPrev} {plnPrev}</a><br />
                                  <a target="_blank" href={wiki}>{pfn} {pln}</a>
                              </p>
                          </div>
                  } else {
                      wikipedia_string =
                          <div>
                              <h3>Wikipedia Links:</h3>
                              <p><a target="_blank" href={wikiPrev}>{pfnPrev} {plnPrev}</a><br />
                                  <a target="_blank" href={wiki}>{pfn} {pln}</a><br />
                                  <a target="_blank" href={wikiNext}>{pfnNext} {plnNext}</a>
                              </p>
                          </div>
                  }

                  createTerms(0);
                  break;
              }

              else if (date_ms === pres[num]["pres_start_date_processed"] && pres[num-1]["pres_end_type"] === "DAO") {
                  date_string = date_pretty;
                  quote_string = <div className="quote">&ldquo;{pres[num]["quote"]}&rdquo;</div>;
                  president_info_string = <div><h1>{pfn} {pln}</h1><p>became President on this day, taking over from <strong>{pfnPrev} {plnPrev}</strong>. He would be succeeded by <strong>{pfnNext} {plnNext}</strong> on {convert_dates(edm)} {edd} {edy} ({days_to_go} {days_to_go_text} later)</p></div>;
                  /*Need to have this so Benjamin Harrison doesn't have two Grover Cleveland Wikipedia links*/
                  if (pres[num]["number"] === 23) {
                      wikipedia_string =
                          <div>
                              <h3>Wikipedia Links:</h3>
                              <p><a target="_blank" href={wikiPrev}>{pfnPrev} {plnPrev}</a><br />
                                  <a target="_blank" href={wiki}>{pfn} {pln}</a>
                              </p>
                          </div>
                  } else {
                      wikipedia_string =
                          <div>
                              <h3>Wikipedia Links:</h3>
                              <p><a target="_blank" href={wikiPrev}>{pfnPrev} {plnPrev}</a><br />
                                  <a target="_blank" href={wiki}>{pfn} {pln}</a><br />
                                  <a target="_blank" href={wikiNext}>{pfnNext} {plnNext}</a>
                              </p>
                          </div>
                  }
                  createTerms(0);
                  break;
              }

              else if (date_ms === pres[num]["pres_start_date_processed"] && pres[num-1]["pres_end_type"] === "DIO") {
                  date_string = date_pretty;
                  quote_string = <div className="quote">&ldquo;{pres[num-1]["quote"]}&rdquo;</div>;
                  president_info_string = <div><h1>{pfnPrev} {plnPrev}</h1><p>died in office on this day at the age of {pres[num-1]["age_at_death"]}; his Vice President, <strong>{pfn} {pln}</strong>, assumed the Presidency. {pln} would be succeeded by <strong>{pfnNext} {plnNext}</strong> on {convert_dates(edm)} {edd} {edy} ({days_to_go} {days_to_go_text} later).</p></div>;
                  wikipedia_string =
                      <div>
                          <h3>Wikipedia Links:</h3>
                          <p><a target="_blank" href={wikiPrev}>{pfnPrev} {plnPrev}</a><br />
                              <a target="_blank" href={wiki}>{pfn} {pln}</a><br />
                              <a target="_blank" href={wikiNext}>{pfnNext} {plnNext}</a></p>
                      </div>
                      createTerms(1);
                  break;
              }

              else if (date_ms === pres[num]["pres_start_date_processed"] && pres[num-1]["pres_end_type"] === "AIO") {
                  date_string = date_pretty;
                  quote_string = <div className="quote">&ldquo;{pres[num-1]["quote"]}&rdquo;</div>;
                  president_info_string = <div><h1>{pfnPrev} {plnPrev}</h1><p>was assassinated on this day at the age of {pres[num-1]["age_at_death"]}; his Vice President, <strong>{pfn} {pln}</strong>, assumed the Presidency. {pln} would be succeeded by <strong>{pfnNext} {plnNext}</strong> on {convert_dates(edm)} {edd} {edy} ({days_to_go} {days_to_go_text} later).</p></div>;
                  wikipedia_string =
                      <div>
                          <h3>Wikipedia Links:</h3>
                          <p><a target="_blank" href={wikiPrev}>{pfnPrev} {plnPrev}</a><br />
                              <a target="_blank" href={wiki}>{pfn} {pln}</a><br />
                              <a target="_blank" href={wikiNext}>{pfnNext} {plnNext}</a></p>
                      </div>
                      createTerms(1);
                  break;

              }

              else if (date_ms === pres[num]["pres_start_date_processed"] && pres[num-1]["pres_end_type"] === "RFO") {
                  date_string = date_pretty;
                  quote_string = <div className="quote">&ldquo;{pres[num-1]["quote"]}&rdquo;</div>;
                  president_info_string = <div><h1>{pfnPrev} {plnPrev}</h1><p>resigned from office on this day; his Vice President, <strong>{pfn} {pln}</strong>, assumed the Presidency. {pln} would be succeeded by <strong>{pfnNext} {plnNext}</strong> on {convert_dates(edm)} {edd} {edy} ({days_to_go} {days_to_go_text} later).</p></div>;
                  wikipedia_string =
                      <div>
                          <h3>Wikipedia Links:</h3>
                          <p><a target="_blank" href={wikiPrev}>{pfnPrev} {plnPrev}</a><br />
                              <a target="_blank" href={wiki}>{pfn} {pln}</a><br />
                              <a target="_blank" href={wikiNext}>{pfnNext} {plnNext}</a></p>
                      </div>
                      createTerms(1);
                  break;
              }

          }

          /*Washington*/
          else if (date_ms === pres[num]["pres_start_date_processed"] && pres[num]["number"] === 1) {
              date_string = date_pretty;
              quote_string = <div className="quote">&ldquo;{pres[num]["quote"]}&rdquo;</div>;
              president_info_string = <div><h1>{pfn} {pln}</h1><p>became the first President of the USA on this day; he would be succeeded by <strong>{pfnNext} {plnNext}</strong> on {convert_dates(edm)} {edd} {edy} ({days_to_go} {days_to_go_text} later).</p></div>;
              wikipedia_string =
                  <div>
                      <h3>Wikipedia Links:</h3>
                      <p><a target="_blank" href={wiki}>{pfn} {pln}</a><br />
                          <a target="_blank" href={wikiNext}>{pfnNext} {plnNext}</a></p>
                  </div>
                  createTerms(0);
              break;
          }

          else if (date_ms > pres[num]["pres_start_date_processed"] &&date_ms < pres[num]["pres_end_date_processed"] && pres[num]["number"] === 1) {
              date_string = date_pretty;
              quote_string = <div className="quote">&ldquo;{pres[num]["quote"]}&rdquo;</div>;
              president_info_string = <div><h1>{pfn} {pln}</h1><p>was President; he had been in office since {convert_dates(sdm)} {sdd} {sdy} ({days_since} {days_since_text} before) and would be succeeded by <strong>{pfnNext} {plnNext}</strong> on {convert_dates(edm)} {edd} {edy} ({days_to_go} {days_to_go_text} later).</p></div>;
              wikipedia_string =
                  <div>
                      <h3>Wikipedia Links:</h3>
                      <p><a target="_blank" href={wiki}>{pfn} {pln}</a><br />
                          <a target="_blank" href={wikiNext}>{pfnNext} {plnNext}</a></p>
                  </div>
                  createTerms(0);
              break;
          }

          /*Incumbent*/
          else if (date_ms > pres[num]["pres_start_date_processed"] && pres[num]["pres_end_date_processed"] === null &&date_ms === today && pres[num]["number"] > index_pres.length-1) {
              date_string = date_pretty;
              quote_string = <div className="quote">&ldquo;{pres[num]["quote"]}&rdquo;</div>;
              president_info_string = <div><h1>{pfn} {pln}</h1><p>is President; he has been in office since {convert_dates(sdm)} {sdd} {sdy} ({days_since} {days_since_text} ago), taking over from <strong>{pfnPrev} {plnPrev}</strong>.</p></div>;
              wikipedia_string =
                  <div>
                      <h3>Wikipedia Links:</h3>
                      <p><a target="_blank" href={wikiPrev}>{pfnPrev} {plnPrev}</a><br />
                          <a target="_blank" href={wiki}>{pfn} {pln}</a></p>
                  </div>
                  incumbent_flag = "true";
              createTerms(0);
              break;
          }

          else if (date_ms > pres[num]["pres_start_date_processed"] && pres[num]["number"] > index_pres.length-1) {
              date_string = date_pretty;
              quote_string = <div className="quote">&ldquo;{pres[num]["quote"]}&rdquo;</div>;
              president_info_string = <div><h1>{pfn} {pln}</h1><p>was President; he had been in office since {convert_dates(sdm)} {sdd} {sdy} ({days_since} {days_since_text} before), taking over from <strong>{pfnPrev} {plnPrev}</strong>.</p></div>;
              wikipedia_string =
                  <div>
                      <h3>Wikipedia Links:</h3>
                      <p><a target="_blank" href={wikiPrev}>{pfnPrev} {plnPrev}</a><br />
                          <a target="_blank" href={wiki}>{pfn} {pln}</a></p>
                  </div>
                  incumbent_flag = "true";
              createTerms(0);
              break;
          }

          else if (date_ms === pres[num]["pres_start_date_processed"] && pres[num]["number"] > index_pres.length-1) {
              date_string = date_pretty;
              quote_string = <div className="quote">&ldquo;{pres[num]["quote"]}&rdquo;</div>;
              president_info_string = <div><h1>{pfn} {pln}</h1><p>became President, taking over from <strong>{pfnPrev} {plnPrev}</strong>.</p></div>;
              wikipedia_string =
                  <div>
                      <h3>Wikipedia Links:</h3>
                      <p><a target="_blank" href={wikiPrev}>{pfnPrev} {plnPrev}</a><br />
                          <a target="_blank" href={wiki}>{pfn} {pln}</a></p>
                  </div>
                  incumbent_flag = "true";
              createTerms(0);
              break;
          }
      }
      /*End of for loop*/


      this.setState (
          {
              quote: quote_string,
              president_info: president_info_string,
              incumbent_flag: incumbent_flag,
              date: date_string,
              wikipedia: wikipedia_string,
              term_divs_html: term_divs,
              term_texts_html: term_texts,
              terms_header: terms_header,
              pointer_class: pointer_class,
              date_perc_css: date_perc_styles
          }
      )



  }


  show_incumbent() {
      if (this.state.incumbent_flag === "true") return <p className="incumbent">*assumed dates as incumbent</p>
  }





  render() {
      return (

          <div className="modal fade" tabIndex="-1" role="dialog" id="modal">
              <div className="modal-dialog" role="document">
                  <div className="modal-content">
                      <div className="modal-header">
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                          <h4 className="modal-title">{this.state.date}</h4>
                      </div>
                      <div className="modal-body">
                          <div className="president-info">
                              {this.state.quote}
                              {this.state.president_info}
                              {this.state.terms_header}
                              <h3>Term Dates</h3>
                              <ul className="terms-list">
                                  { this.state.term_texts_html.map((i) => {return i})}
                              </ul>
                              {this.show_incumbent()}
                              <div className="terms-holder">
                                  { this.state.term_divs_html.map((i) => {return i})}
                              </div>
                              <div className="date-holder">
                                  <span className="pointer" style={this.state.date_perc_css}><span className={this.state.pointer_class}>{this.state.date}</span></span>
                              </div>
                              {this.state.wikipedia}
                          </div>
                      </div>
                      <div className="modal-footer">
                          <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                      </div>
                  </div>
              </div>
          </div>

      )
  }


}
/*Modal*/


export default App;

