//import React from 'react';
//import ReactDOM from 'react-dom';
//import './index.css';
//import App from './App';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import reportWebVitals from './reportWebVitals';
//
//ReactDOM.render(
//  <React.StrictMode>
//    <App />
//  </React.StrictMode>,
//  document.getElementById('root')
//);
//
//// If you want your app to work offline and load faster, you can change
//// unregister() to register() below. Note this comes with some pitfalls.
//// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.register();
//
//// If you want to start measuring performance in your app, pass a function
//// to log results (for example: reportWebVitals(console.log))
//// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();


import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';






const presidents = [
    //pres_end_types: DIO (Died in office); AIO, (Assassinated in office); RFO, (Resigned from office); DAO, (Died after office), and ""
        {
            "number": 1,
            "president": {
                "president_first_name": "George",
                "president_last_name": "Washington"
            },
            "birth_date": {
                "birth_date_day": 22,
                "birth_date_month": 2,
                "birth_date_year": 1732
            },
            "death_date": {
                "death_date_day": 14,
                "death_date_month": 12,
                "death_date_year": 1799
            },
            "terms": {
                "1" : {
                    "term-start-date": "30 April, 1789",
                    "term-end-date": "4 March, 1793"
                },
                "2" : {
                    "term-start-date": "4 March, 1793",
                    "term-end-date": "4 March, 1797"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 30,
                "pres_start_date_month": 4,
                "pres_start_date_year": 1789
            },
            "pres_end_date": {
                "pres_end_date_day": 4,
                "pres_end_date_month": 3,
                "pres_end_date_year": 1797
            },
            "pres_end_type": "DAO",
            "party": "Unafilliated",
            "wikipedia_link": "https://en.wikipedia.org/wiki/George_Washington",
            "quote": "There is a Destiny which has the control of our actions, not to be resisted by the strongest efforts of Human Nature."
    },
        {
            "number": 2,
            "president": {
                "president_first_name": "John",
                "president_last_name": "Adams"
            },
            "birth_date": {
                "birth_date_day": 30,
                "birth_date_month": 10,
                "birth_date_year": 1735
            },
            "death_date": {
                "death_date_day": 4,
                "death_date_month": 7,
                "death_date_year": 1826
            },
            "terms": {
                "1" : {
                    "term-start-date": "4 March 1797",
                    "term-end-date": "4 March, 1801"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 4,
                "pres_start_date_month": 3,
                "pres_start_date_year": 1797
            },
            "pres_end_date": {
                "pres_end_date_day": 4,
                "pres_end_date_month": 3,
                "pres_end_date_year": 1801
            },
            "pres_end_type": "DAO",
            "party": "Federalist",
            "wikipedia_link": "https://en.wikipedia.org/wiki/John_Adams",
            "quote": "Facts are stubborn things; and whatever may be our wishes, our inclinations, or the dictates of our passions, they cannot alter the state of facts and evidence."
    },
        {
            "number": 3,
            "president": {
                "president_first_name": "Thomas",
                "president_last_name": "Jefferson"
            },
            "birth_date": {
                "birth_date_day": 13,
                "birth_date_month": 4,
                "birth_date_year": 1743
            },
            "death_date": {
                "death_date_day": 4,
                "death_date_month": 7,
                "death_date_year": 1826
            },
            "terms": {
                "1" : {
                    "term-start-date": "March 4, 1801",
                    "term-end-date": "March 4, 1805"
                },
                "2" : {
                    "term-start-date": "March 4, 1805",
                    "term-end-date": "March 4, 1809"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 4,
                "pres_start_date_month": 3,
                "pres_start_date_year": 1801
            },
            "pres_end_date": {
                "pres_end_date_day": 4,
                "pres_end_date_month": 3,
                "pres_end_date_year": 1809
            },
            "pres_end_type": "DAO",
            "party": "Democratic-Republican",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Thomas_Jefferson",
            "quote": "The tree of liberty must be refreshed from time to time with the blood of patriots and tyrants. It is its natural manure."
    },
        {
            "number": 4,
            "president": {
                "president_first_name": "James",
                "president_last_name": "Madison"
            },
            "birth_date": {
                "birth_date_day": 16,
                "birth_date_month": 3,
                "birth_date_year": 1751
            },
            "death_date": {
                "death_date_day": 28,
                "death_date_month": 6,
                "death_date_year": 1836
            },
            "terms": {
                "1" : {
                    "term-start-date": "March 4, 1809",
                    "term-end-date": "March 4, 1813"
                },
                "2" : {
                    "term-start-date": "March 4, 1813",
                    "term-end-date": "March 4, 1817"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 4,
                "pres_start_date_month": 3,
                "pres_start_date_year": 1809
            },
            "pres_end_date": {
                "pres_end_date_day": 4,
                "pres_end_date_month": 3,
                "pres_end_date_year": 1817
            },
            "pres_end_type": "DAO",
            "party": "Democratic-Republican",
            "wikipedia_link": "https://en.wikipedia.org/wiki/James_Madison",
            "quote": "Let me recommend the best medicine in the world: a long journey, at a mild season, through a pleasant country, in easy stages."
    },
        {
            "number": 5,
            "president": {
                "president_first_name": "James",
                "president_last_name": "Monroe"
            },
            "birth_date": {
                "birth_date_day": 28,
                "birth_date_month": 4,
                "birth_date_year": 1758
            },
            "death_date": {
                "death_date_day": 4,
                "death_date_month": 7,
                "death_date_year": 1831
            },
            "terms": {
                "1" : {
                    "term-start-date": "March 4, 1817",
                    "term-end-date": "March 5, 1821"
                },
                "2" : {
                    "term-start-date": "March 5, 1821",
                    "term-end-date": "March 4, 1825"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 4,
                "pres_start_date_month": 3,
                "pres_start_date_year": 1817
            },
            "pres_end_date": {
                "pres_end_date_day": 4,
                "pres_end_date_month": 3,
                "pres_end_date_year": 1825
            },
            "pres_end_type": "DAO",
            "party": "Democratic-Republican",
            "wikipedia_link": "https://en.wikipedia.org/wiki/James_Monroe",
            "quote": "Our country may be likened to a new house. We lack many things, but we possess the most precious of all - liberty!"
    },
        {
            "number": 6,
            "president": {
                "president_first_name": "John Quincy",
                "president_last_name": "Adams"
            },
            "birth_date": {
                "birth_date_day": 11,
                "birth_date_month": 7,
                "birth_date_year": 1767
            },
            "death_date": {
                "death_date_day": 23,
                "death_date_month": 2,
                "death_date_year": 1848
            },
            "terms": {
                "1" : {
                    "term-start-date": "March 4, 1825",
                    "term-end-date": "March 4, 1829"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 4,
                "pres_start_date_month": 3,
                "pres_start_date_year": 1825
            },
            "pres_end_date": {
                "pres_end_date_day": 4,
                "pres_end_date_month": 3,
                "pres_end_date_year": 1829
            },
            "pres_end_type": "DAO",
            "party": "Democratic-Republican",
            "wikipedia_link": "https://en.wikipedia.org/wiki/John_Quincy_Adams",
            "quote": "Courage and perseverance have a magical talisman, before which difficulties disappear and obstacles vanish into air."
    },
        {
            "number": 7,
            "president": {
                "president_first_name": "Andrew",
                "president_last_name": "Jackson"
            },
            "birth_date": {
                "birth_date_day": 15,
                "birth_date_month": "Mar",
                "birth_date_year": 1767
            },
            "death_date": {
                "death_date_day": 8,
                "death_date_month": 6,
                "death_date_year": 1845
            },
            "terms": {
                "1" : {
                    "term-start-date": "March 4, 1829",
                    "term-end-date": "March 4, 1833"
                },
                "2" : {
                    "term-start-date": "March 4, 1833",
                    "term-end-date": "March 4, 1837"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 4,
                "pres_start_date_month": 3,
                "pres_start_date_year": 1829
            },
            "pres_end_date": {
                "pres_end_date_day": 4,
                "pres_end_date_month": 3,
                "pres_end_date_year": 1837
            },
            "pres_end_type": "DAO",
            "party": "Democratic",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Andrew_Jackson",
            "quote": "Take time to deliberate; but when the time for action arrives, stop thinking and go in."
    },
        {
            "number": 8,
            "president": {
                "president_first_name": "Martin",
                "president_last_name": "Van Buren"
            },
            "birth_date": {
                "birth_date_day": 5,
                "birth_date_month": 12,
                "birth_date_year": 1782
            },
            "death_date": {
                "death_date_day": 24,
                "death_date_month": 7,
                "death_date_year": 1862
            },
            "terms": {
                "1" : {
                    "term-start-date": "March 4, 1837",
                    "term-end-date": "March 4, 1841"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 4,
                "pres_start_date_month": 3,
                "pres_start_date_year": 1837
            },
            "pres_end_date": {
                "pres_end_date_day": 4,
                "pres_end_date_month": 3,
                "pres_end_date_year": 1841
            },
            "pres_end_type": "DAO",
            "party": "Democratic",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Martin_Van_Buren",
            "quote": "It is easier to do a job right than to explain why you didn't."
    },
        {
            "number": 9,
            "president": {
                "president_first_name": "William Henry",
                "president_last_name": "Harrison"
            },
            "birth_date": {
                "birth_date_day": 9,
                "birth_date_month": 2,
                "birth_date_year": 1773
            },
            "death_date": {
                "death_date_day": 4,
                "death_date_month": 4,
                "death_date_year": 1841
            },
            "terms": {
                "1" : {
                    "term-start-date": "March 4, 1841",
                    "term-end-date": "April 4, 1841"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 4,
                "pres_start_date_month": 3,
                "pres_start_date_year": 1841
            },
            "pres_end_date": {
                "pres_end_date_day": 4,
                "pres_end_date_month": 4,
                "pres_end_date_year": 1841
            },
            "pres_end_type": "DIO",
            "party": "Whig",
            "wikipedia_link": "https://en.wikipedia.org/wiki/William_Henry_Harrison",
            "quote": "The only legitimate right to govern is an express grant of power from the governed."
    },
        {
            "number": 10,
            "president": {
                "president_first_name": "John",
                "president_last_name": "Tyler"
            },
            "birth_date": {
                "birth_date_day": 29,
                "birth_date_month": 3,
                "birth_date_year": 1790
            },
            "death_date": {
                "death_date_day": 18,
                "death_date_month": 1,
                "death_date_year": 1862
            },
            "terms": {
                "1" : {
                    "term-start-date": "April 4, 1841",
                    "term-end-date": "March 4, 1845"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 4,
                "pres_start_date_month": 4,
                "pres_start_date_year": 1841
            },
            "pres_end_date": {
                "pres_end_date_day": 4,
                "pres_end_date_month": 3,
                "pres_end_date_year": 1845
            },
            "pres_end_type": "DAO",
            "party": "Whig, then Unaffiliated",
            "wikipedia_link": "https://en.wikipedia.org/wiki/John_Tyler",
            "quote": "Nature governs man by no principle more fixed than that which leads him to pursue his interest."
    },
        {
            "number": 11,
            "president": {
                "president_first_name": "James K.",
                "president_last_name": "Polk"
            },
            "birth_date": {
                "birth_date_day": 2,
                "birth_date_month": 11,
                "birth_date_year": 1795
            },
            "death_date": {
                "death_date_day": 15,
                "death_date_month": 6,
                "death_date_year": 1849
            },
            "terms": {
                "1" : {
                    "term-start-date": "March 4, 1845",
                    "term-end-date": "March 4, 1849"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 4,
                "pres_start_date_month": 3,
                "pres_start_date_year": 1845
            },
            "pres_end_date": {
                "pres_end_date_day": 4,
                "pres_end_date_month": 3,
                "pres_end_date_year": 1849
            },
            "pres_end_type": "DAO",
            "party": "Democratic",
            "wikipedia_link": "https://en.wikipedia.org/wiki/James_K._Polk",
            "quote": "With me it is exceptionally true that the Presidency is no bed of roses."
    },
        {
            "number": 12,
            "president": {
                "president_first_name": "Zachary",
                "president_last_name": "Taylor"
            },
            "birth_date": {
                "birth_date_day": 24,
                "birth_date_month": 11,
                "birth_date_year": 1784
            },
            "death_date": {
                "death_date_day": 9,
                "death_date_month": 7,
                "death_date_year": 1850
            },
            "terms": {
                "1" : {
                    "term-start-date": "March 4, 1849",
                    "term-end-date": "July 9, 1850"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 4,
                "pres_start_date_month": 3,
                "pres_start_date_year": 1849
            },
            "pres_end_date": {
                "pres_end_date_day": 9,
                "pres_end_date_month": 7,
                "pres_end_date_year": 1850
            },
            "pres_end_type": "DIO",
            "party": "Whig",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Zachary_Taylor",
            "quote": "Never judge a stranger by his clothes."
    },
        {
            "number": 13,
            "president": {
                "president_first_name": "Millard",
                "president_last_name": "Fillmore"
            },
            "birth_date": {
                "birth_date_day": 7,
                "birth_date_month": 1,
                "birth_date_year": 1800
            },
            "death_date": {
                "death_date_day": 8,
                "death_date_month": 3,
                "death_date_year": 1874
            },
            "terms": {
                "1" : {
                    "term-start-date": "July 9, 1850",
                    "term-end-date": "March 4, 1853"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 9,
                "pres_start_date_month": 7,
                "pres_start_date_year": 1850
            },
            "pres_end_date": {
                "pres_end_date_day": 4,
                "pres_end_date_month": 3,
                "pres_end_date_year": 1853
            },
            "pres_end_type": "DAO",
            "party": "Whig",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Millard_Fillmore",
            "quote": "May God save the country, for it is evident that the people will not."
    },
        {
            "number": 14,
            "president": {
                "president_first_name": "Franklin",
                "president_last_name": "Pierce"
            },
            "birth_date": {
                "birth_date_day": 23,
                "birth_date_month": 11,
                "birth_date_year": 1804
            },
            "death_date": {
                "death_date_day": 8,
                "death_date_month": 10,
                "death_date_year": 1869
            },
            "terms": {
                "1" : {
                    "term-start-date": "March 4, 1853",
                    "term-end-date": "March 4, 1857"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 4,
                "pres_start_date_month": 3,
                "pres_start_date_year": 1853
            },
            "pres_end_date": {
                "pres_end_date_day": 4,
                "pres_end_date_month": 3,
                "pres_end_date_year": 1857
            },
            "pres_end_type": "DAO",
            "party": "Democratic",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Franklin_Pierce",
            "quote": "Frequently the more trifling the subject, the more animated and protracted the discussion."
    },
        {
            "number": 15,
            "president": {
                "president_first_name": "James",
                "president_last_name": "Buchanan"
            },
            "birth_date": {
                "birth_date_day": 23,
                "birth_date_month": 4,
                "birth_date_year": 1791
            },
            "death_date": {
                "death_date_day": 1,
                "death_date_month": 6,
                "death_date_year": 1868
            },
            "terms": {
                "1" : {
                    "term-start-date": "March 4, 1857",
                    "term-end-date": "March 4, 1861"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 4,
                "pres_start_date_month": 3,
                "pres_start_date_year": 1857
            },
            "pres_end_date": {
                "pres_end_date_day": 4,
                "pres_end_date_month": 3,
                "pres_end_date_year": 1861
            },
            "pres_end_type": "DAO",
            "party": "Democratic",
            "wikipedia_link": "https://en.wikipedia.org/wiki/James_Buchanan",
            "quote": "What is right and what is practicable are two different things."
    },
        {
            "number": 16,
            "president": {
                "president_first_name": "Abraham",
                "president_last_name": "Lincoln"
            },
            "birth_date": {
                "birth_date_day": 12,
                "birth_date_month": 2,
                "birth_date_year": 1809
            },
            "death_date": {
                "death_date_day": 15,
                "death_date_month": 4,
                "death_date_year": 1865
            },
            "terms": {
                "1" : {
                    "term-start-date": "March 4, 1861",
                    "term-end-date": "March 4, 1865"
                },
                "2" : {
                    "term-start-date": "March 4, 1865",
                    "term-end-date": "April 15, 1865"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 4,
                "pres_start_date_month": 3,
                "pres_start_date_year": 1861
            },
            "pres_end_date": {
                "pres_end_date_day": 15,
                "pres_end_date_month": 4,
                "pres_end_date_year": 1865
            },
            "pres_end_type": "AIO",
            "party": "Republican(National Union)",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Abraham_Lincoln",
            "quote": "Be sure you put your feet in the right place, then stand firm."
    },
        {
            "number": 17,
            "president": {
                "president_first_name": "Andrew",
                "president_last_name": "Johnson"
            },
            "birth_date": {
                "birth_date_day": 29,
                "birth_date_month": 12,
                "birth_date_year": 1808
            },
            "death_date": {
                "death_date_day": 31,
                "death_date_month": 7,
                "death_date_year": 1875
            },
            "terms": {
                "1" : {
                    "term-start-date": "April 15, 1865",
                    "term-end-date": "March 4, 1869"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 15,
                "pres_start_date_month": 4,
                "pres_start_date_year": 1865
            },
            "pres_end_date": {
                "pres_end_date_day": 4,
                "pres_end_date_month": 3,
                "pres_end_date_year": 1869
            },
            "pres_end_type": "DAO",
            "party": "National Union, then Democratic",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Andrew_Johnson",
            "quote": "The goal to strive for is a poor government but a rich people."
    },
        {
            "number": 18,
            "president": {
                "president_first_name": "Ulysses S.",
                "president_last_name": "Grant"
            },
            "birth_date": {
                "birth_date_day": 27,
                "birth_date_month": 4,
                "birth_date_year": 1822
            },
            "death_date": {
                "death_date_day": 23,
                "death_date_month": 7,
                "death_date_year": 1885
            },
            "terms": {
                "1" : {
                    "term-start-date": "March 4, 1869",
                    "term-end-date": "March 4, 1873"
                },
                "2" : {
                    "term-start-date": "March 4, 1873",
                    "term-end-date": "March 4, 1877"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 4,
                "pres_start_date_month": 3,
                "pres_start_date_year": 1869
            },
            "pres_end_date": {
                "pres_end_date_day": 4,
                "pres_end_date_month": 3,
                "pres_end_date_year": 1877
            },
            "pres_end_type": "DAO",
            "party": "Republican",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Ulysses_S._Grant",
            "quote": "I have never advocated war except as a means of peace."
    },
        {
            "number": 19,
            "president": {
                "president_first_name": "Rutherford B.",
                "president_last_name": "Hayes"
            },
            "birth_date": {
                "birth_date_day": 4,
                "birth_date_month": 10,
                "birth_date_year": 1822
            },
            "death_date": {
                "death_date_day": 17,
                "death_date_month": 1,
                "death_date_year": 1893
            },
            "terms": {
                "1" : {
                    "term-start-date": "March 4, 1877",
                    "term-end-date": "March 4, 1881"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 4,
                "pres_start_date_month": 3,
                "pres_start_date_year": 1877
            },
            "pres_end_date": {
                "pres_end_date_day": 4,
                "pres_end_date_month": 3,
                "pres_end_date_year": 1881
            },
            "pres_end_type": "DAO",
            "party": "Republican",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Rutherford_B._Hayes",
            "quote": "Unjust attacks on public men do them more good than unmerited praise."
    },
        {
            "number": 20,
            "president": {
                "president_first_name": "James A.",
                "president_last_name": "Garfield"
            },
            "birth_date": {
                "birth_date_day": 19,
                "birth_date_month": 11,
                "birth_date_year": 1831
            },
            "death_date": {
                "death_date_day": 19,
                "death_date_month": 9,
                "death_date_year": 1881
            },
            "terms": {
                "1" : {
                    "term-start-date": "March 4, 1881",
                    "term-end-date": "19 September, 1881"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 4,
                "pres_start_date_month": 3,
                "pres_start_date_year": 1881
            },
            "pres_end_date": {
                "pres_end_date_day": 19,
                "pres_end_date_month": 9,
                "pres_end_date_year": 1881
            },
            "pres_end_type": "AIO",
            "party": "Republican",
            "wikipedia_link": "https://en.wikipedia.org/wiki/James_A._Garfield",
            "quote": "A brave man is a man who dares to look the Devil in the face and tell him he is a Devil."
    },
        {
            "number": 21,
            "president": {
                "president_first_name": "Chester A.",
                "president_last_name": "Arthur"
            },
            "birth_date": {
                "birth_date_day": 5,
                "birth_date_month": 10,
                "birth_date_year": 1829
            },
            "death_date": {
                "death_date_day": 18,
                "death_date_month": 11,
                "death_date_year": 1886
            },
            "terms": {
                "1" : {
                    "term-start-date": "September 19, 1881",
                    "term-end-date": "March 4, 1885"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 19,
                "pres_start_date_month": 9,
                "pres_start_date_year": 1881
            },
            "pres_end_date": {
                "pres_end_date_day": 4,
                "pres_end_date_month": 3,
                "pres_end_date_year": 1885
            },
            "pres_end_type": "DAO",
            "party": "Republican",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Chester_A._Arthur",
            "quote": "If you are not too large for the place you occupy, you are too small for it."
    },
        {
            "number": 22,
            "president": {
                "president_first_name": "Grover",
                "president_last_name": "Cleveland"
            },
            "birth_date": {
                "birth_date_day": 18,
                "birth_date_month": 3,
                "birth_date_year": 1837
            },
            "death_date": {
                "death_date_day": 24,
                "death_date_month": 6,
                "death_date_year": 1908
            },
            "terms": {
                "1" : {
                    "term-start-date": "March 4, 1885",
                    "term-end-date": "March 4, 1889"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 4,
                "pres_start_date_month": 3,
                "pres_start_date_year": 1885
            },
            "pres_end_date": {
                "pres_end_date_day": 4,
                "pres_end_date_month": 3,
                "pres_end_date_year": 1889
            },
            "pres_end_type": "DAO",
            "party": "Democratic",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Grover_Cleveland",
            "quote": "Some day I will be better remembered."
    },
        {
            "number": 23,
            "president": {
                "president_first_name": "Benjamin",
                "president_last_name": "Harrison"
            },
            "birth_date": {
                "birth_date_day": 20,
                "birth_date_month": 8,
                "birth_date_year": 1833
            },
            "death_date": {
                "death_date_day": 13,
                "death_date_month": 3,
                "death_date_year": 1901
            },
            "terms": {
                "1" : {
                    "term-start-date": "March 4, 1889",
                    "term-end-date": "March 4, 1893"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 4,
                "pres_start_date_month": 3,
                "pres_start_date_year": 1889
            },
            "pres_end_date": {
                "pres_end_date_day": 4,
                "pres_end_date_month": 3,
                "pres_end_date_year": 1893
            },
            "pres_end_type": "DAO",
            "party": "Republican",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Benjamin_Harrison",
            "quote": "I pity the man who wants a coat so cheap that the man or woman who produces the cloth will starve in the process."
    },
        {
            "number": 24,
            "president": {
                "president_first_name": "Grover",
                "president_last_name": "Cleveland"
            },
            "birth_date": {
                "birth_date_day": 18,
                "birth_date_month": 3,
                "birth_date_year": 1837
            },
            "death_date": {
                "death_date_day": 24,
                "death_date_month": 6,
                "death_date_year": 1908
            },
            "terms": {
                "1" : {
                    "term-start-date": "March 4, 1893",
                    "term-end-date": "March 4, 1897"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 4,
                "pres_start_date_month": 3,
                "pres_start_date_year": 1893
            },
            "pres_end_date": {
                "pres_end_date_day": 4,
                "pres_end_date_month": 3,
                "pres_end_date_year": 1897
            },
            "pres_end_type": "DAO",
            "party": "Democratic",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Grover_Cleveland",
            "quote": "I have tried so hard to do right."
    },
        {
            "number": 25,
            "president": {
                "president_first_name": "William",
                "president_last_name": "McKinley"
            },
            "birth_date": {
                "birth_date_day": 29,
                "birth_date_month": 1,
                "birth_date_year": 1843
            },
            "death_date": {
                "death_date_day": 14,
                "death_date_month": 9,
                "death_date_year": 1901
            },
            "terms": {
                "1" : {
                    "term-start-date": "March 4, 1897",
                    "term-end-date": "March 4, 1901"
                },
                "2" : {
                    "term-start-date": "March 4, 1897",
                    "term-end-date": "September 14, 1901"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 4,
                "pres_start_date_month": 3,
                "pres_start_date_year": 1897
            },
            "pres_end_date": {
                "pres_end_date_day": 14,
                "pres_end_date_month": 9,
                "pres_end_date_year": 1901
            },
            "pres_end_type": "AIO",
            "party": "Republican",
            "wikipedia_link": "https://en.wikipedia.org/wiki/William_McKinley",
            "quote": "That's all a man can hope for during his lifetime - to set an example - and when he is dead, to be an inspiration for history."
    },
        {
            "number": 26,
            "president": {
                "president_first_name": "Theodore",
                "president_last_name": "Roosevelt"
            },
            "birth_date": {
                "birth_date_day": 27,
                "birth_date_month": 10,
                "birth_date_year": 1858
            },
            "death_date": {
                "death_date_day": 6,
                "death_date_month": 1,
                "death_date_year": 1919
            },
            "terms": {
                "1" : {
                    "term-start-date": "September 14, 1901",
                    "term-end-date": "March 4, 1905"
                },
                "2" : {
                    "term-start-date": "March 4, 1905",
                    "term-end-date": "March 4, 1909"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 14,
                "pres_start_date_month": 9,
                "pres_start_date_year": 1901
            },
            "pres_end_date": {
                "pres_end_date_day": 4,
                "pres_end_date_month": 3,
                "pres_end_date_year": 1909
            },
            "pres_end_type": "DAO",
            "party": "Republican",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Theodore_Roosevelt",
            "quote": "Nobody cares how much you know, until they know how much you care."
    },
        {
            "number": 27,
            "president": {
                "president_first_name": "William Howard",
                "president_last_name": "Taft"
            },
            "birth_date": {
                "birth_date_day": 15,
                "birth_date_month": 9,
                "birth_date_year": 1857
            },
            "death_date": {
                "death_date_day": 8,
                "death_date_month": 3,
                "death_date_year": 1930
            },
            "terms": {
                "1" : {
                    "term-start-date": "March 4, 1909",
                    "term-end-date": "March 4, 1913"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 4,
                "pres_start_date_month": 3,
                "pres_start_date_year": 1909
            },
            "pres_end_date": {
                "pres_end_date_day": 4,
                "pres_end_date_month": 3,
                "pres_end_date_year": 1913
            },
            "pres_end_type": "DAO",
            "party": "Republican",
            "wikipedia_link": "https://en.wikipedia.org/wiki/William_Howard_Taft",
            "quote": "No tendency is quite so strong in human nature as the desire to lay down rules of conduct for other people."
    },
        {
            "number": 28,
            "president": {
                "president_first_name": "Woodrow",
                "president_last_name": "Wilson"
            },
            "birth_date": {
                "birth_date_day": 28,
                "birth_date_month": 12,
                "birth_date_year": 1856
            },
            "death_date": {
                "death_date_day": 3,
                "death_date_month": 2,
                "death_date_year": 1924
            },
            "terms": {
                "1" : {
                    "term-start-date": "March 4, 1913",
                    "term-end-date": "March 4, 1917"
                },
                "2" : {
                    "term-start-date": "March 4, 1917",
                    "term-end-date": "March 4, 1921"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 4,
                "pres_start_date_month": 3,
                "pres_start_date_year": 1913
            },
            "pres_end_date": {
                "pres_end_date_day": 4,
                "pres_end_date_month": 3,
                "pres_end_date_year": 1921
            },
            "pres_end_type": "DAO",
            "party": "Democratic",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Woodrow_Wilson",
            "quote": "If a dog will not come to you after having looked you in the face, you should go home and examine your conscience."
    },
        {
            "number": 29,
            "president": {
                "president_first_name": "Warren G.",
                "president_last_name": "Harding"
            },
            "birth_date": {
                "birth_date_day": 2,
                "birth_date_month": 11,
                "birth_date_year": 1865
            },
            "death_date": {
                "death_date_day": 2,
                "death_date_month": 8,
                "death_date_year": 1923
            },
            "terms": {
                "1" : {
                    "term-start-date": "March 4, 1921",
                    "term-end-date": "August 2, 1923"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 4,
                "pres_start_date_month": 3,
                "pres_start_date_year": 1921
            },
            "pres_end_date": {
                "pres_end_date_day": 2,
                "pres_end_date_month": 8,
                "pres_end_date_year": 1923
            },
            "pres_end_type": "DIO",
            "party": "Republican",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Warren_G._Harding",
            "quote": "Only solitary men know the full joys of friendship. Others have their family; but to a solitary and an exile his friends are everything."
    },
        {
            "number": 30,
            "president": {
                "president_first_name": "Calvin",
                "president_last_name": "Coolidge"
            },
            "birth_date": {
                "birth_date_day": 4,
                "birth_date_month": 7,
                "birth_date_year": 1872
            },
            "death_date": {
                "death_date_day": 5,
                "death_date_month": 1,
                "death_date_year": 1933
            },
            "terms": {
                "1" : {
                    "term-start-date": "August 2, 1923",
                    "term-end-date": "March 4, 1925"
                },
                "2" : {
                    "term-start-date": "March 4, 1925",
                    "term-end-date": "March 4, 1929"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 2,
                "pres_start_date_month": 8,
                "pres_start_date_year": 1923
            },
            "pres_end_date": {
                "pres_end_date_day": 4,
                "pres_end_date_month": 3,
                "pres_end_date_year": 1929
            },
            "pres_end_type": "DAO",
            "party": "Republican",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Calvin_Coolidge",
            "quote": "Don't expect to build up the weak by pulling down the strong."
    },
        {
            "number": 31,
            "president": {
                "president_first_name": "Herbert",
                "president_last_name": "Hoover"
            },
            "birth_date": {
                "birth_date_day": 10,
                "birth_date_month": 8,
                "birth_date_year": 1874
            },
            "death_date": {
                "death_date_day": 20,
                "death_date_month": 10,
                "death_date_year": 1964
            },
            "terms": {
                "1" : {
                    "term-start-date": "March 4, 1929",
                    "term-end-date": "March 4, 1933"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 4,
                "pres_start_date_month": 3,
                "pres_start_date_year": 1929
            },
            "pres_end_date": {
                "pres_end_date_day": 4,
                "pres_end_date_month": 3,
                "pres_end_date_year": 1933
            },
            "pres_end_type": "DAO",
            "party": "Republican",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Herbert_Hoover",
            "quote": "Freedom is the open window through which pours the sunlight of the human spirit and human dignity."
    },
        {
            "number": 32,
            "president": {
                "president_first_name": "Franklin D.",
                "president_last_name": "Roosevelt"
            },
            "birth_date": {
                "birth_date_day": 30,
                "birth_date_month": 1,
                "birth_date_year": 1882
            },
            "death_date": {
                "death_date_day": 12,
                "death_date_month": 4,
                "death_date_year": 1945
            },
            "terms": {
                "1" : {
                    "term-start-date": "March 4, 1933",
                    "term-end-date": "January 20, 1937"
                },
                "2" : {
                    "term-start-date": "January 20, 1937",
                    "term-end-date": "January 20, 1941"
                },
                "3" : {
                    "term-start-date": "January 20, 1941",
                    "term-end-date": "January 20, 1945"
                },
                "4" : {
                    "term-start-date": "January 20, 1945",
                    "term-end-date": "April 12, 1945"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 4,
                "pres_start_date_month": 3,
                "pres_start_date_year": 1933
            },
            "pres_end_date": {
                "pres_end_date_day": 12,
                "pres_end_date_month": 4,
                "pres_end_date_year": 1945
            },
            "pres_end_type": "DIO",
            "party": "Democratic",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Franklin_D._Roosevelt",
            "quote": "When you come to the end of your rope, tie a knot and hang on."
    },
        {
            "number": 33,
            "president": {
                "president_first_name": "Harry S.",
                "president_last_name": "Truman"
            },
            "birth_date": {
                "birth_date_day": 8,
                "birth_date_month": 5,
                "birth_date_year": 1884
            },
            "death_date": {
                "death_date_day": 26,
                "death_date_month": 12,
                "death_date_year": 1972
            },
            "terms": {
                "1" : {
                    "term-start-date": "April 12, 1945",
                    "term-end-date": "January 20, 1949"
                },
                "2" : {
                    "term-start-date": "January 20, 1949",
                    "term-end-date": "January 20, 1953"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 12,
                "pres_start_date_month": 4,
                "pres_start_date_year": 1945
            },
            "pres_end_date": {
                "pres_end_date_day": 20,
                "pres_end_date_month": 1,
                "pres_end_date_year": 1953
            },
            "pres_end_type": "DAO",
            "party": "Democratic",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Harry_S._Truman",
            "quote": "I never did give anybody hell. I just told the truth and they thought it was hell."
    },
        {
            "number": 34,
            "president": {
                "president_first_name": "Dwight D.",
                "president_last_name": "Eisenhower"
            },
            "birth_date": {
                "birth_date_day": 14,
                "birth_date_month": 10,
                "birth_date_year": 1890
            },
            "death_date": {
                "death_date_day": 28,
                "death_date_month": 3,
                "death_date_year": 1969
            },
            "terms": {
                "1" : {
                    "term-start-date": "January 20, 1953",
                    "term-end-date": "January 20, 1957"
                },
                "2" : {
                    "term-start-date": "January 20, 1957",
                    "term-end-date": "January 20, 1961"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 20,
                "pres_start_date_month": 1,
                "pres_start_date_year": 1953
            },
            "pres_end_date": {
                "pres_end_date_day": 20,
                "pres_end_date_month": 1,
                "pres_end_date_year": 1961
            },
            "pres_end_type": "DAO",
            "party": "Republican",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Dwight_D._Eisenhower",
            "quote": "Farming looks mighty easy when your plow is a pencil and you're a thousand miles from the corn field."
    },
        {
            "number": 35,
            "president": {
                "president_first_name": "John F.",
                "president_last_name": "Kennedy"
            },
            "birth_date": {
                "birth_date_day": 29,
                "birth_date_month": 5,
                "birth_date_year": 1917
            },
            "death_date": {
                "death_date_day": 22,
                "death_date_month": 11,
                "death_date_year": 1963
            },
            "terms": {
                "1" : {
                    "term-start-date": "January 20, 1961",
                    "term-end-date": "November 22, 1963"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 20,
                "pres_start_date_month": 1,
                "pres_start_date_year": 1961
            },
            "pres_end_date": {
                "pres_end_date_day": 22,
                "pres_end_date_month": 11,
                "pres_end_date_year": 1963
            },
            "pres_end_type": "AIO",
            "party": "Democratic",
            "wikipedia_link": "https://en.wikipedia.org/wiki/John_F._Kennedy",
            "quote": "Let us not seek the Republican answer or the Democratic answer, but the right answer. Let us not seek to fix the blame for the past. Let us accept our own responsibility for the future."
    },
        {
            "number": 36,
            "president": {
                "president_first_name": "Lyndon B.",
                "president_last_name": "Johnson"
            },
            "birth_date": {
                "birth_date_day": 27,
                "birth_date_month": 8,
                "birth_date_year": 1908
            },
            "death_date": {
                "death_date_day": 22,
                "death_date_month": 1,
                "death_date_year": 1973
            },
            "terms": {
                "1" : {
                    "term-start-date": "November 22, 1963",
                    "term-end-date": "January 20, 1965"
                },
                "2" : {
                    "term-start-date": "January 20, 1965",
                    "term-end-date": "January 20, 1969"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 22,
                "pres_start_date_month": 11,
                "pres_start_date_year": 1963
            },
            "pres_end_date": {
                "pres_end_date_day": 20,
                "pres_end_date_month": 1,
                "pres_end_date_year": 1969
            },
            "pres_end_type": "DAO",
            "party": "Democratic",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Lyndon_B._Johnson",
            "quote": "Being president is like being a jackass in a hailstorm. There's nothing to do but to stand there and take it."
    },
        {
            "number": 37,
            "president": {
                "president_first_name": "Richard",
                "president_last_name": "Nixon"
            },
            "birth_date": {
                "birth_date_day": 9,
                "birth_date_month": 1,
                "birth_date_year": 1913
            },
            "death_date": {
                "death_date_day": 22,
                "death_date_month": 4,
                "death_date_year": 1994
            },
            "terms": {
                "1" : {
                    "term-start-date": "January 20, 1969",
                    "term-end-date": "January 20, 1973"
                },
                "2" : {
                    "term-start-date": "January 20, 1973",
                    "term-end-date": "August 9, 1974"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 20,
                "pres_start_date_month": 1,
                "pres_start_date_year": 1969
            },
            "pres_end_date": {
                "pres_end_date_day": 9,
                "pres_end_date_month": 8,
                "pres_end_date_year": 1974
            },
            "pres_end_type": "RFO",
            "party": "Republican",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Richard_Nixon",
            "quote": "Always remember: others may hate you, but those who hate you don't win unless you hate them."
    },
        {
            "number": 38,
            "president": {
                "president_first_name": "Gerald",
                "president_last_name": "Ford"
            },
            "birth_date": {
                "birth_date_day": 14,
                "birth_date_month": 7,
                "birth_date_year": 1913
            },
            "death_date": {
                "death_date_day": 26,
                "death_date_month": 12,
                "death_date_year": 2006
            },
            "terms": {
                "1" : {
                    "term-start-date": "August 9, 1974",
                    "term-end-date": "January 20, 1977"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 9,
                "pres_start_date_month": 8,
                "pres_start_date_year": 1974
            },
            "pres_end_date": {
                "pres_end_date_day": 20,
                "pres_end_date_month": 1,
                "pres_end_date_year": 1977
            },
            "pres_end_type": "DAO",
            "party": "Republican",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Gerald_Ford",
            "quote": "A government big enough to give you everything you want is a government big enough to take from you everything you have."
    },
        {
            "number": 39,
            "president": {
                "president_first_name": "Jimmy",
                "president_last_name": "Carter"
            },
            "birth_date": {
                "birth_date_day": 1,
                "birth_date_month": 10,
                "birth_date_year": 1924
            },
            "death_date": {
                "death_date_day": null,
                "death_date_month": null,
                "death_date_year": null
            },
            "terms": {
                "1" : {
                    "term-start-date": "January 20, 1977",
                    "term-end-date": "January 20, 1981"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 20,
                "pres_start_date_month": 1,
                "pres_start_date_year": 1977
            },
            "pres_end_date": {
                "pres_end_date_day": 20,
                "pres_end_date_month": 1,
                "pres_end_date_year": 1981
            },
            "pres_end_type": "DAO",
            "party": "Democratic",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Jimmy_Carter",
            "quote": "You can do what you have to do, and sometimes you can do it even better than you think you can."
    },
        {
            "number": 40,
            "president": {
                "president_first_name": "Ronald",
                "president_last_name": "Reagan"
            },
            "birth_date": {
                "birth_date_day": 6,
                "birth_date_month": 2,
                "birth_date_year": 1911
            },
            "death_date": {
                "death_date_day": 5,
                "death_date_month": 6,
                "death_date_year": 2004
            },
            "terms": {
                "1" : {
                    "term-start-date": "January 20, 1981",
                    "term-end-date": "January 20, 1985"
                },
                "2" : {
                    "term-start-date": "January 20, 1985",
                    "term-end-date": "January 20, 1989"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 20,
                "pres_start_date_month": 1,
                "pres_start_date_year": 1981
            },
            "pres_end_date": {
                "pres_end_date_day": 20,
                "pres_end_date_month": 1,
                "pres_end_date_year": 1989
            },
            "pres_end_type": "DAO",
            "party": "Republican",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Ronald_Reagan",
            "quote": "Life is one grand, sweet song, so start the music."
    },
        {
            "number": 41,
            "president": {
                "president_first_name": "George H.W.",
                "president_last_name": "Bush"
            },
            "birth_date": {
                "birth_date_day": 12,
                "birth_date_month": 6,
                "birth_date_year": 1924
            },
            "death_date": {
                "death_date_day": 30,
                "death_date_month": 11,
                "death_date_year": 2018
            },
            "terms": {
                "1" : {
                    "term-start-date": "January 20, 1989",
                    "term-end-date": "January 20, 1993"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 20,
                "pres_start_date_month": 1,
                "pres_start_date_year": 1989
            },
            "pres_end_date": {
                "pres_end_date_day": 20,
                "pres_end_date_month": 1,
                "pres_end_date_year": 1993
            },
            "pres_end_type": "DAO",
            "party": "Republican",
            "wikipedia_link": "https://en.wikipedia.org/wiki/George_H._W._Bush",
            "quote": "I have opinions of my own, strong opinions, but I don't always agree with them."
    },
        {
            "number": 42,
            "president": {
                "president_first_name": "Bill",
                "president_last_name": "Clinton"
            },
            "birth_date": {
                "birth_date_day": 19,
                "birth_date_month": 8,
                "birth_date_year": 1946
            },
            "death_date": {
                "death_date_day": null,
                "death_date_month": null,
                "death_date_year": null
            },
            "terms": {
                "1" : {
                    "term-start-date": "January 20, 1993",
                    "term-end-date": "January 20, 1997"
                },
                "2" : {
                    "term-start-date": "January 20, 1997",
                    "term-end-date": "January 20, 2001"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 20,
                "pres_start_date_month": 1,
                "pres_start_date_year": 1993
            },
            "pres_end_date": {
                "pres_end_date_day": 20,
                "pres_end_date_month": 1,
                "pres_end_date_year": 2001
            },
            "pres_end_type": "DAO",
            "party": "Democratic",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Bill_Clinton",
            "quote": "There is nothing wrong with America that cannot be cured by what is right with America."
    },
        {
            "number": 43,
            "president": {
                "president_first_name": "George W.",
                "president_last_name": "Bush"
            },
            "birth_date": {
                "birth_date_day": 6,
                "birth_date_month": 7,
                "birth_date_year": 1946
            },
            "death_date": {
                "death_date_day": null,
                "death_date_month": null,
                "death_date_year": null
            },
            "terms": {
                "1" : {
                    "term-start-date": "January 20, 2001",
                    "term-end-date": "January 20, 2005"
                },
                "2" : {
                    "term-start-date": "January 20, 2005",
                    "term-end-date": "January 20, 2009"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 20,
                "pres_start_date_month": 1,
                "pres_start_date_year": 2001
            },
            "pres_end_date": {
                "pres_end_date_day": 20,
                "pres_end_date_month": 1,
                "pres_end_date_year": 2009
            },
            "pres_end_type": "DAO",
            "party": "Republican",
            "wikipedia_link": "https://en.wikipedia.org/wiki/George_W._Bush",
            "quote": "Americans are rising to the tasks of history, and they expect the same of us."
    },
        {
            "number": 44,
            "president": {
                "president_first_name": "Barack",
                "president_last_name": "Obama"
            },
            "birth_date": {
                "birth_date_day": 4,
                "birth_date_month": 8,
                "birth_date_year": 1961
            },
            "death_date": {
                "death_date_day": null,
                "death_date_month": null,
                "death_date_year": null
            },
            "terms": {
                "1" : {
                    "term-start-date": "January 20, 2009",
                    "term-end-date": "January 20, 2013"
                },
                "2" : {
                    "term-start-date": "January 20, 2013",
                    "term-end-date": "January 20, 2017"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 20,
                "pres_start_date_month": 1,
                "pres_start_date_year": 2009
            },
            "pres_end_date": {
                "pres_end_date_day": 20,
                "pres_end_date_month": 1,
                "pres_end_date_year": 2017
            },
            "pres_end_type": "DAO",
            "party": "Democratic",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Barack_Obama",
            "quote": "If you're walking down the right path and you're willing to keep walking, eventually you'll make progress."
    },
        {
            "number": 45,
            "president": {
                "president_first_name": "Donald",
                "president_last_name": "Trump"
            },
            "birth_date": {
                "birth_date_day": 14,
                "birth_date_month": 6,
                "birth_date_year": 1946
            },
            "death_date": {
                "death_date_day": null,
                "death_date_month": null,
                "death_date_year": null
            },
            "terms": {
                "1" : {
                    "term-start-date": "January 20, 2017",
                    "term-end-date": "January 20, 2021"
                }
            },
            "pres_start_date": {
                "pres_start_date_day": 20,
                "pres_start_date_month": 1,
                "pres_start_date_year": 2017
            },
            "pres_end_date": {
                "pres_end_date_day": 20,
                "pres_end_date_month": 1,
                "pres_end_date_year": 2021
            },
            "pres_end_type": "DAO",
            "party": "Republican",
            "wikipedia_link": "https://en.wikipedia.org/wiki/Donald_Trump",
            "quote": "You have to think anyway, so why not think big?"
    },
    {
        "number": 46,
        "president": {
            "president_first_name": "Joe",
            "president_last_name": "Biden"
        },
        "birth_date": {
            "birth_date_day": 20,
            "birth_date_month": 11,
            "birth_date_year": 1942
        },
        "death_date": {
            "death_date_day": null,
            "death_date_month": null,
            "death_date_year": null
        },
        "terms": {
            "1" : {
                "term-start-date": "January 20, 2021",
                "term-end-date": "January 20, 2025"
            }
        },
        "pres_start_date": {
            "pres_start_date_day": 20,
            "pres_start_date_month": 1,
            "pres_start_date_year": 2021
        },
        "pres_end_date": {
            "pres_end_date_day": null,
            "pres_end_date_month": null,
            "pres_end_date_year": null
        },
        "pres_end_type": "",
        "party": "Democrat",
        "wikipedia_link": "https://en.wikipedia.org/wiki/Joe_Biden",
        "quote": "Given a fair shot, given a fair chance, Americans have never, ever, ever, ever let their country down. Never. Never. Ordinary people like us. Who do extraordinary things."
},
];

/*Do some prelimanary work on the object*/

var birth_date_full = null;
var death_date_full = null;
var pres_start_date_full = null;
var pres_end_date_full = null;
var birth_date_processed = null;
var death_date_processed = null;
var pres_start_date_processed = null;
var pres_end_date_processed = null;
var index_pres = Object.keys(presidents);







/*Process dates and add to pres object*/
for (var i = 0; index_pres.length > i; i++) {



    /*In this format, we need to subtract 1 from month because it is zero based, however when date format is string we do not need to do this*/
    birth_date_full =
        new Date(presidents[i]["birth_date"]["birth_date_year"],
                 presidents[i]["birth_date"]["birth_date_month"] - 1,
                 presidents[i]["birth_date"]["birth_date_day"]
                );

    presidents[i]["birth_date_full"] = birth_date_full;
    birth_date_processed = birth_date_full.getTime();
    presidents[i]["birth_date_processed"] = birth_date_processed;


    if (presidents[i]["death_date"]["death_date_year"]) {

        death_date_full =
            new Date(presidents[i]["death_date"]["death_date_year"],
                     presidents[i]["death_date"]["death_date_month"] - 1,
                     presidents[i]["death_date"]["death_date_day"]
                    );

        presidents[i]["death_date_full"] = death_date_full;
        death_date_processed = death_date_full.getTime();
        presidents[i]["death_date_processed"] = death_date_processed;

        presidents[i]["age_at_death"] = Math.floor( ((death_date_processed - birth_date_processed)  / 86400000) / 365);

    } else {
        presidents[i]["death_date_full"] = null;
        presidents[i]["death_date_processed"] = null
        presidents[i]["age_at_death"] = null
    }



    pres_start_date_full =
        new Date(presidents[i]["pres_start_date"]["pres_start_date_year"],
                 presidents[i]["pres_start_date"]["pres_start_date_month"] - 1,
                 presidents[i]["pres_start_date"]["pres_start_date_day"]
                );

    presidents[i]["pres_start_date_full"] = pres_start_date_full;
    pres_start_date_processed = pres_start_date_full.getTime();
    presidents[i]["pres_start_date_processed"] = pres_start_date_processed;



    if (presidents[i]["pres_end_date"]["pres_end_date_year"]) {

        pres_end_date_full =
            new Date(presidents[i]["pres_end_date"]["pres_end_date_year"],
                     presidents[i]["pres_end_date"]["pres_end_date_month"] - 1,
                     presidents[i]["pres_end_date"]["pres_end_date_day"]
                    );

        presidents[i]["pres_end_date_full"] = pres_end_date_full;
        pres_end_date_processed = pres_end_date_full.getTime();
        presidents[i]["pres_end_date_processed"] = pres_end_date_processed;

    } else {
        presidents[i]["pres_end_date_full"] = null
        presidents[i]["pres_end_date_processed"] = null
    }
}





ReactDOM.render(<App pres = {presidents}/>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
